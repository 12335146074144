import { useTranslation } from 'react-i18next'
import {
  AtlasBodyMain,
  AtlasBodySubtitle,
  AtlasH3,
  AtlasListAtom,
  CardComponent,
  Filler,
  Icon,
  Icons,
  PageContentAtom,
  SpacerAtom, StackAtom,
  TextBoxMolecule,
  ToolbarMolecule
} from '@justomx/atlas-components'
import ChangeWeightMolecule from 'components/molecules/ChangeWeight.molecule'
import ListOfWeights from 'components/molecules/ListOfWeights.molecule'
import { formatNumber, formatWeight } from 'utils/product'
import { formatString } from 'utils/string'
import { FlexColumnAtom } from 'components/atoms/FlexColumn.atom'
import LoaderAtom from 'components/atoms/Loader.atom'
import { useRegisterRestock } from './RegisterRestock.hook'
import { ProductCard } from 'components/molecules/v2/ProductCard.molecule'
import { LocationAssignmentsMolecule } from '../../../../components/molecules/LocationAssignments.molecule'
import { ExpectedRestockMolecule } from '../../../../components/molecules/ExpectedRestock.molecule'
import { ConfirmationDialogMolecule } from '../../../../components/molecules/ConfirmationDialog.molecule'

export const AlertsRegisterRestockPage = () => {
  const { t } = useTranslation('global')

  const {
    isLoading,
    product,
    stock,
    expectedStock,
    setQuantityToRestock,
    quantityToRestock,
    weights,
    totalWeight,
    weightToDelete,
    suggestedLocations,
    partialRestockDialogRef,
    deleteWeightDialogRef,
    actions
  } = useRegisterRestock()

  if (isLoading) {
    return <LoaderAtom>{t('loader.message')}</LoaderAtom>
  }

  if (!product) {
    return <AtlasH3>Error al obtener la información del producto</AtlasH3>
  }

  return <>
    <ToolbarMolecule
      title={t('alerts.register.toolbar-title')}
      actionIcon={Icons.ArrowBack}
      onAction={actions.goBack}
    />
    <PageContentAtom>
      <AtlasBodySubtitle>{t('alerts.register.instructions')}</AtlasBodySubtitle>
      <AtlasListAtom>
        <li><AtlasBodyMain
          display="contents">{t(`alerts.register.set-quantity${product.isWeighable ? '-weighable' : ''}`)}</AtlasBodyMain></li>
        <li><AtlasBodyMain display="contents">{t('alerts.register.scan-destination')}</AtlasBodyMain></li>
      </AtlasListAtom>
      <CardComponent $variant="highlight" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <AtlasBodyMain>
          {t('alerts.register.stock-in-location', {
            stock: formatNumber(stock),
            uom: product.isWeighable ? 'g' : 'uds'
          })}
        </AtlasBodyMain>
        <ExpectedRestockMolecule isWeighable={product.isWeighable} expectedStock={expectedStock} />
        <Icon src={Icons.ArrowForward} color="white" size={16} />
        <LocationAssignmentsMolecule locations={suggestedLocations?.map((it) => it.name) ?? []} />
        <SpacerAtom height={20} />
        <FlexColumnAtom style={{
          display: expectedStock === 1 ? 'none' : 'block',
          width: '100%'
        }}>
          {
            !product.isWeighable
              ? <TextBoxMolecule
                type="number"
                label={t('inventory-waste.register-waste.units')}
                onChange={(value) => {
                  setQuantityToRestock(parseInt(value))
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    const target = e.target as HTMLInputElement
                    target.blur()
                  }
                }}
              />
              : <ChangeWeightMolecule
                onAdd={(value) => {
                  actions.addItem(value)
                }}
                label={t('inventory-waste.register-waste.grams')}
              />
          }
        </FlexColumnAtom>
      </CardComponent>
      {product.isWeighable && <ListOfWeights
        detail={formatString(t('inventory-waste.register-waste.total-qty'), formatWeight(totalWeight, 2))}
        items={weights}
        // onDelete={openDeleteDialog}
        onDelete={actions.onRemoveItem}
      />}
      <Filler />
      <ProductCard
        product={{ ...product, zone: '' }}
        showTitle={true}
      />
      <ConfirmationDialogMolecule
        dialogRef={partialRestockDialogRef}
        confirmText="Reabastecer"
        cancelText="Cancelar"
        onConfirm={actions.confirmRestock}
        onCancel={actions.clearDestination}
      >
        <StackAtom gap={12}>
          <AtlasBodySubtitle>{t('alerts.register.partial-qty-confirmation.title')}</AtlasBodySubtitle>
          <AtlasBodyMain>
            {t('alerts.register.partial-qty-confirmation.body', {
              quantity: product.isWeighable ? totalWeight : quantityToRestock,
              pendingQuantity: expectedStock ?? 0,
              unit: product.isWeighable ? 'g' : 'uds'
            })}
          </AtlasBodyMain>
        </StackAtom>
      </ConfirmationDialogMolecule>
      <ConfirmationDialogMolecule
        dialogRef={deleteWeightDialogRef}
        confirmText={t('fast-set-weight.dialog-delete.btn-confirm')}
        onConfirm={actions.confirmRemoveItem}
        cancelText={t('fast-set-weight.dialog-delete.btn-cancel')}>
        {
          formatString(t('fast-set-weight.dialog-delete.msg'), formatWeight(weightToDelete))
        }
      </ConfirmationDialogMolecule>
    </PageContentAtom>
  </>
}
