{
  "loader": {
    "message": "Espere um momento, por favor..."
  },
  "commons": {
    "continue": "Continuar",
    "cancel": "Cancelar",
    "finish": "Finalizar"
  },
  "validations": {
    "temp-range": "A temperatura não está dentro da faixa permitida.",
    "generic-error": "Algo deu errado",
    "required": "Campo obrigatório",
    "invalid-amount": "Insira uma quantidade válida",
    "greater-total-units": "A quantidade é maior que a solicitada, digite a quantidade correta.",
    "expired-date": "A data inserida está expirada",
    "alphanum": "Insira um valor alfanumérico",
    "single-select": "Selecione uma opção",
    "multi-select": "Selecione pelo menos uma opção",
    "invalid-date": "Formato de data inválido"
  },
  "home": {
    "btn-use-es": "Usar español",
    "btn-use-br": "Usar português",
    "link-transfer-inventory": "Transferência",
    "link-fix-inventory": "Corrigir Inventário",
    "link-fast-transfer": "Transferência Rápida",
    "link-container-management": "Gerenciamento de pallets",
    "link-alerts": "Alertas",
    "storage-urls": "Almacenaje",
    "receipt-urls": "Recibo",
    "link-inventory-waste": "Inventário de resíduos",
    "link-inventory-transfer": "Transferências de inventário",
    "link-search": "Buscador",
    "link-reverse-logistics": "Logística inversa"
  },
  "alerts": {
    "default-error": "Ocorreu um erro ao obter pallets com alertas críticos",
    "toolbar-title": "Alertas de reabastecimento",
    "scan-product-error": "Houve um erro ao escanear o produto:",
    "scan-container-error": "Houve um erro ao escanear o recipiente:",
    "container-not-in-list": "Escaneaste una tarima que no está en el listado, ¿Quieres continuar?",
    "v2": {
      "toolbar-title": "Alertas de reabastecimento",
      "scan":  "Escaneie o pallet para iniciar o reabastecimento:"
    },
    "scan-product": {
      "scan": "Escaneie o produto a reabastecer:",
      "alerts-in-container": "Alertas do pallet",
      "toolbar-title": "Pallet {{container}}",
      "empty-list": "Sem alertas",
      "card-quantity-title": "Quantidade a ser reabastecida:",
      "tabs":{
        "Urgent": "Urgentes ({{counter}})",
        "Critical": "Críticas ({{counter}})",
        "Warning": "Preventivas ({{counter}})"
      },
      "product-not-in-container":{
        "title": "Relatório de produto",
        "body": "Se o produto não estiver no pallet, selecione Continuar. Lembre-se de revisar o pallet completo."
      },
      "error-not-in-list": "O produto {{product}}, não está registrado no inventário do pallet, adicione-o com Corrigir inventário."
    },
    "register": {
      "toolbar-title": "Reabastece",
      "instructions": "Instruções",
      "set-quantity": "Digite a quantidade reabastecida.",
      "set-quantity-weighable": "Escanea o escribe la cantidad.",
      "scan-destination": "Depois, escaneie a ubicação de picking.",
      "stock-in-location": "{{stock}} {{uom}} en este pallet",
      "suggested-location": "Ubicações a ser reabastecidas",
      "partial-qty-confirmation": {
        "title": "Resumo do reabastecimento",
        "body": "Você completou {{quantity}} {{unit}} de {{pendingQuantity}} {{unit}} solicitados. Você continuará com o reabastecimento deste produto?"
      },
      "success": "Foram reabastecidas {{quantity}} {{uom}} de {{product}}.",
      "error-location-not-suggested": "Ubicação incorreta. Coloque o produto em uma das ubicações sugeridas.",
      "stock-error": "A quantidade a ser reabastecida é maior que a quantidade de estoque disponível no pallet.",
      "quantity-error": "Quantidade inválida. Você deve escrever um valor maior que zero."
    }
  },
  "container-management": {
    "default-error": "Ocorreu um erro ao obter pallets com alertas críticos",
    "toolbar-title": "Empilhadeira",
    "btn-upload-container": "Plataforma para subir",
    "instructions": "Instruções",
    "lower-instructions": "Para descer: Escaneie o pallet",
    "lift-instructions": "Para subir: Selecione Subir pallet",
    "error-reading-container": "Ocorreu um erro ao escanear o pallet, tente novamente."
  },
  "scan-to-lift": {
    "toolbar-title": "Pallet para subir",
    "scan": "Escaneie",
    "container": "Pallet",
    "use-your-scanner": "Use o seu scanner"
  },
  "scan-dest-to-lift": {
    "toolbar-title": "Escanear uma localização de destino",
    "scan": "Escaneie",
    "storage-location": "Local de Armazenamento",
    "use-your-scanner": "Use o seu scanner",
    "container-to-lift": "Pallet para subir",
    "no-container-in-location": "Não há pallet em {0} ({1})",
    "container-not-in-rack": "O pallet {0} não pode subir porque está em {1}"
  },
  "confirm-lift": {
    "toolbar-title": "Subir pallet",
    "origin-text": "Pallet a subir",
    "destination-text": "Ubicação de destino sugerida",
    "scan": "Escaneie a ubicação de armazenamento.",
    "success": "O pallet {{containerName}} foi subido para a ubicação {{locationName}}"
  },
  "lower-container": {
    "toolbar-title": "Descer pallet",
    "origin-text": "Pallet a descer",
    "destination-text": "Ubicação de destino sugerida",
    "scan": "Escaneie a ubicação de destino.  ",
    "success": "O palete {{containerName}} foi baixado para a ubicação {{locationName}}"
  },
  "traslation":{
    "choose-container":{
      "not-found": "O pallet escaneado não existe em local selecionado"
    }
  },
  "fast-traslation": {
    "toolbar-title": "Escaneie",
    "scan": "Escaneie",
    "use-your-scanner": "Use o seu scanner"
  },
  "fast-scan-product": {
    "toolbar-title": "Reabasto",
    "title-critical-alerts": "Com alertas críticos",
    "title-warning-alerts": "Com alertas preventivos",
    "title-no-alerts": "Sem alertas",
    "bottom-msg": "Escanee o produto que deseja reabastecer"
  },
  "fast-set-quantity": {
    "toolbar-title": "Escreva a quantidade",
    "total-qty": "Quantidade total em inventário: {0}",
    "description": {
      "pre": "Há",
      "high": "{0} unidades",
      "post": "no pallet {0}"
    },
    "label-restocking": "Reabastecer",
    "label-qty": "Quantidade",
    "btn-confirm": "Confirmar"
  },
  "fast-set-weight": {
    "toolbar-title": "Reabastecer",
    "total-qty": "Quantidade total em inventário: {0}",
    "description": {
      "pre": "Há",
      "post": "na localização"
    },
    "label-restocking1": "Reabastecer",
    "label-restocking2": "Você pode reabastecer até",
    "label-qty": "Quantidade em gramas",
    "total-restocking": "Você escaneou {0}",
    "btn-confirm": "Confirmar",
    "btn-add": "Adicionar",
    "error-01": "Erro ao extrair o peso do EAN {0}, você pode inseri-lo manualmente.",
    "error-02": "O produto {0} não corresponde ao selecionado",
    "dialog-delete": {
      "msg": "Deseja excluir {0} da quantidade reabastecida?",
      "btn-cancel": "Cancelar",
      "btn-confirm": "Excluir"
    },
    "dialog-confirm": {
      "msg1": "Você reabasteceu {0} dos {1} solicitados, deseja finalizar o reabastecimento deste produto?",
      "msg2": "Você quer reabastecer {0}? Deseja finalizar o reabastecimento deste produto?",
      "btn-cancel": "Cancelar",
      "btn-confirm": "Finalizar"
    }
  },
  "fast-scan-dest": {
    "toolbar-title": "Escaneie",
    "scan": "Escaneie",
    "picking-location": "Ubicação de picking",
    "use-your-scanner": "Use o seu scanner",
    "suggested-locations": "Locais sugeridos",
    "current-locations": "Locais atuais"
  },
  "product-containers": {
    "toolbar-title": "Detalhe do produto",
    "no-containers": "Não foram encontrados estoques deste produto em locais de armazenamento, pré-armazenamento ou em prateleiras.",
    "no-picking": "Não foram encontrados estoques deste produto nas localizações de picking.",
    "in-picking": "Em picking",
    "in-storage": "Em armazenamento",
    "in-pre-storage": "Em pré-armazenamento",
    "in-rack": "Em rack"
  },
  "search-product": {
    "toolbar-title": "Pesquisar produto",
    "scan": "Escaneie",
    "product": "Producto",
    "use-your-scanner": "Use o seu scanner",
    "enter-ean": "Digite EAN"
  },
  "choose-container": {
    "toolbar-title": "Escolha o pallet",
    "containers-in-location": "Pallets no ubicação:",
    "scan-container": "Escaneie o pallet para iniciar a transferência",
    "see-products": "Toque para ver os produtos no contêiner",
    "location-with-loose-product": "Ubicação com produto solto",
    "loose-product": {
      "title": "Produto solto",
      "items": ["Ubicação de picking", "Pallet de armazenagem", "Pallet de pré-armazenagem"]
    }
  },
  "move-all":{
    "toolbar-title": "Resumo do movimento",
    "products-to-move": "Produtos a serem movidos",
    "product-qty": "Quantidade"
  },
  "scan-origin":{
    "toolbar-title": "Transferência de produto",
    "scan": "Escaneie",
    "location": "Localização",
    "container": "Pallet",
    "use-your-scanner": "Use o seu scanner",
    "insert-last-four-digits": "Digite os 4 últimos dígitos do pallet"
  },
  "move-products-selector":{
    "product":{
      "title": "Produto",
      "items": ["Pré-armazenagem", "Armazenagem", "Produtos do pallet a uma ubicação de pciking"]
    },
    "container":{
      "title": "Pallet",
      "items": ["Ubicação de picking", "Pallet de armazenagem", "Pallet de pré-armazenagem"]
    },
    "loose-product":{
      "title": "Produto solto",
      "items": ["Pré-armazenagem", "Armazenagem", "Produtos do pallet a uma ubicação de pciking"]
    }
  },
  "scan-destination":{
    "toolbar-title": "Escaneie",
    "title": "Ubicação de destino",
    "scan": "Escaneie",
    "use-your-scanner": "Use o seu scanner"
  },
  "product-info-restocking": {
    "total-in-origin": "Cantidad en origen: {0}",
    "to-restock": "Reabastecer {0}",
    "units": "unidades"
  },
  "to-storage": {
    "title": "Armazenamento",
    "description": "Escanee o contêiner que deseja armazenar",
    "no-containers": "Nenhum contêiner foi encontrado.",
    "default-error": "Ocorreu um erro ao obter os contêineres."
  },
  "to-storage-container-description": {
    "title-critical-alerts": "Com alertas críticos",
    "title-warning-alerts": "Com alertas preventivas",
    "title-no-alerts": "Sem alertas",
    "continue": "Continuar"
  },
  "to-storage-destination-location": {
    "title": "Escaneie",
    "scan": "Escaneie",
    "location": "Locais de destino",
    "action": "Use seu scanner",
    "suggested": "Local de destino sugerido",
    "ok": "O contêiner foi armazenado"
  },
  "inventory-product-card": {
    "count": "Quantidade total: {{value}}"
  },
  "inventory-product-count-molecule": {
    "description": {
      "pre": "Há",
      "high": "{{value}} {{uom}}",
      "post": "en"
    }
  },
  "storage-home": {
    "invalid-scan": "O contêiner ou localização escaneado não está disponível para armazenar: {{scanned}}"
  },
  "storage-container-detail" : {
    "bad-request":  "Houve um erro inesperado",
    "continue": "Continuar"
  },
  "storage-scan-destination" : {
    "scan":  "Escaneie",
    "location":  "Ubicación de destino",
    "use-your-scanner": "Use o seu scanner",
    "ok": "O contêiner {{container}} foi armazenado em {{destination}} (origem: {{origin}})",
    "bad-request": "Houve um erro inesperado",
    "destination-no-storage": "A localização {{destination}} não é uma localização de armazenamento",
    "destination-groceries-validation": "No se pueden mover productos a una ubicación de abarrotes",
    "no-suggested-locations": "O produto não possui local sugerido porque não possui local de retirada e/ou estoque.",
    "origin": "Origem"
  },
  "uom": {
    "units": "Unidades",
    "grams": "Gramas"
  },
  "receipt": {
    "title": "Recibo do fornecedor",
    "select-order": "Selecione um pedido:",
    "order-id": "Ordem: {{orderId}}",
    "total-sku": "N.º SKU: {{totalSKU}}",
    "type-error": "Esperado um array de pedidos nos dados do carregador",
    "provider": "Fornecedor: {{provider}}",
    "sync": "Sincronizar",
    "no-data-title": "Você não tem nenhuma ordem sincronizada",
    "no-data-help": "Por favor, toque no botão \"Sincronizar\""
  },
  "scanner-molecule": {
    "title": "Escanear",
    "use-scanner": "Usa o teu scanner"
  },
  "receipt-order-detail": {
    "total-sku": "Total de SKU a receber",
    "boxes-counter": "Quantidade solicitada: {{received}}/{{total}} unidades",
    "expected-kg": "Quantidade solicitada: {{received}}/{{total}} quilos",
    "end": "Terminar",
    "pending": "Por receber ({{counter}})",
    "finished": "Registrados ({{counter}})",
    "type-error": "Esperava-se uma ordem de compra nos dados do carregador",
    "scan-product": "Escaneia o produto a receber",
    "invalid-upc": "Escanee um UPC válido. Foi escaneado: {{upc}}",
    "nonexistent-upc": "O produto escaneado não está na ordem de compra atual",
    "registered-upc": "O produto escaneado já foi registrado",
    "upc": "UPC: {{upc}}",
    "full-missing": "Você quer relatar este produto como falta completa?",
    "undo-full-missing": "Você quer que o produto deixe de ser uma falta completa?",
    "missing-error": "Erro ao marcar o produto como falta total"
  },
  "receipt-scan-order": {
    "title": "Número do pedido",
    "scan-label": "Documento de entrada {{type}}",
    "textbox": "Digite o número do pedido de compra",
    "invalid-input": "Escaneie um número de pedido válido. Foi escaneado: {{orderNumber}}",
    "sync-error": "Não foi possível sincronizar a ordem: {{orderNumber}}",
    "sync-success": "Ordem sincronizada."
  },
  "receipt-scan-container": {
    "title": "Escanear",
    "scan-label": "Contentor",
    "product": "Produto escaneado",
    "product-undefined": "Para escanear um contentor, primeiro você deve escanear um produto",
    "invalid-input": "Escanee um LPN válido. Foi escaneado: {{lpn}}",
    "displaced-container": "Este contêiner não está na posição de pré-armazenamento. Por favor, escolha outro contêiner."
  },
  "receipt-product-lots": {
    "measure": "Unidade de medida: {{measure}} unidades por caixa",
    "measure-kg": "Unidade de medida: {{measure}} g por caixa",
    "requested-boxes": "Caixas solicitadas",
    "requested-units": "Unidades solicitadas",
    "requested-quantity": "Quantidade solicitada",
    "add-lot": "Adicionar lote",
    "end": "Finalizar",
    "type-error": "Esperava-se informações de lotes nos dados do carregador",
    "counter": "{{counter}}/{{total}}",
    "counter-kg": "{{counter}} kg/{{total}} kg",
    "get-order-error": "Ocorreu um erro ao recuperar o detalhe da ordem"
  },
  "receipt-lot-form": {
    "lot-number": "Lote: {{lotNumber}}",
    "lot": "Lote",
    "expiration": "Data de validade (dd/mm/aaaa)",
    "units": "Quantidade de unidades",
    "boxes": "Quantidade de caixas",
    "grams": "Quantidade em gramas",
    "temperature": "Temperatura (em graus)"
  },
  "receipt-missing-dialog": {
    "title": "Faltam receber {{missing}} unidades. Como você vai registrá-las?",
    "title-kg": "Faltam receber {{missing}} quilos. Como você vai registrá-los?",
    "title-box": "Faltam receber {{missing}} caixas. Como você vai registrá-las?",
    "continue": "Continuar",
    "back": "Voltar",
    "reject": "Rejeição de produto",
    "partial-missing": "Relatório de falta parcial"
  },
  "receipt-partial-missing": {
    "title": "Registrar a falta",
    "subtitle": "Digite a quantidade que falta do produto",
    "total-missing": "Quantidade em falta",
    "total-kg": "Quantidade em falta em gramas",
    "missing-boxes": "Caixas faltantes",
    "register": "Registrar",
    "type-error": "Esperava-se informações de totais nos dados do carregador",
    "missing-error": "Erro ao armazenar a falta parcial"
  },
  "receipt-rejection": {
    "title": "Registrar a rejeição",
    "evidence": "Envie a evidência",
    "max-evidences": "Máximo de 6 fotos.",
    "form-title": "Digite a quantidade, lote e selecione o motivo",
    "units": "Unidades a rejeitar",
    "units-kg": "Quantidade a rejeitar em gramas",
    "boxes": "Caixas a rejeitar",
    "lot": "Lote",
    "register": "Registrar",
    "upload-error": "Ocorreu um erro ao carregar a imagem, tente novamente",
    "create-error": "Ocorreu um erro ao tentar criar a rejeição",
    "type-error": "Esperava-se a informação de uma rejeição nos dados do carregador",
    "missing-photo": "Você deve tirar pelo menos uma foto da rejeição",
    "reasons": {
      "NEAR_EXPIRATION": "Validade curta",
      "PRODUCT_DAMAGED": "Produto quebrado",
      "WITHOUT_LABELING": "Produto sem rotulagem",
      "MOLD": "Mofo",
      "NO_FUMIGATION_CERT": "Sem certificado de fumigação",
      "NO_APPOINTMENT": "Fornecedor sem agendamento",
      "OUT_OF_HOURS": "Fornecedor fora do horário",
      "HARMFUL_FAUNA": "Fauna nociva",
      "EXTRA_PRODUCT": "Produto extra",
      "BAD_UPC": "UPC não corresponde",
      "PACKAGING_DAMAGE": "Embalagem quebrada",
      "OUT_OF_RANGE_TEMP": "Temperatura fora da faixa",
      "DEHYDRATED": "Desidratação",
      "DECAY": "Apodrecimento",
      "RIPENING": "Amadurecimento",
      "MECHANICAL_DAMAGE": "Dano mecânico",
      "OXIDATION": "Oxidação",
      "SOGGINESS": "Encharcamento",
      "OVER_WEIGHT": "Excesso de peso"
    }
  },
  "receipt-containers": {
    "title": "Contentores",
    "type-error": "Esperava-se um array de contentores nos dados do carregador",
    "toMove": "Para mover ({{counter}})",
    "moved": "Movido ({{counter}})",
    "container": "Contentor: {{container}}",
    "skus": "N.º de SKUs: {{totalSKU}}",
    "scan-label": "Escaneie o contentor para mover.",
    "finish-order": "Finalizar ordem",
    "invalid-container": "O contentor escaneado não existe na ordem atual",
    "location": "Localização escaneada: {{location}}",
    "finish-error": "Ocorreu um erro ao finalizar o recebimento",
    "finish-success": "Recebimento finalizado com sucesso"
  },
  "receipt-summary": {
    "title": "Resumo",
    "type-error": "Esperava-se um array com o resumo do recebimento do produto nos dados do carregador",
    "lot": "Lote: {{lot}}",
    "amount": "Quantidade: {{amount}} unidades",
    "amount-kg": "Quantidade: {{amount}} quilos",
    "expiration": "Validade: {{expiration}}",
    "container": "Contentor: {{container}}",
    "reasons": "Motivo: {{reasons}}",
    "temperature": "Temperatura (em graus): {{temperature}}",
    "missing-reason": "Motivo: não chegou",
    "confirm-delete": "Tem certeza de que deseja excluir o item selecionado?",
    "delete-error": "Ocorreu um erro ao excluir o item selecionado",
    "received": "Produto recebido, para ver mais vá para Registrados.",
    "received-error": "Ocorreu um erro ao receber o produto",
    "status": {
      "received": "Lotes recebidos",
      "rejected": "Rejeitados",
      "missing": "Faltantes"
    }
  },
  "receipt-scan-location": {
    "title": "Escanear",
    "scan-label": "Localização",
    "scanned": "Contentor movido, para ver mais vá para movidos.",
    "move-error": "Ocorreu um erro ao mover o contentor"
  },
  "receipt-type": {
    "title": "Tipo de recebimento",
    "transfers": "Transferência",
    "providers": "Fornecedores",
    "to-receive": "O que você vai receber?",
    "help": "Toque no botão dependendo do que você vai receber."
  },
  "receipt-temperature": {
    "title": "Temperatura",
    "temp-label": "Temperatura (em graus)",
    "capture-temp": "Capturar temperatura",
    "out-of-range": "Fora da faixa, aprovada pela qualidade.",
    "update-success": "Temperatura registrada.",
    "update-error": "Ocorreu um erro ao registrar a temperatura",
    "type-error": "Esperava-se uma ordem de compra ao carregar a tela"
  },
  "inventory-waste": {
    "scan-origin": {
      "toolbar-title": "Escaneie"
    },
    "scan-product": {
      "toolbar-title": "Escaneie"
    },
    "scan-destination": {
      "toolbar-title": "Escaneie",
      "scan": "Escaneie",
      "location": "Localização da perda",
      "use-your-scanner": "Use seu scanner"
    },
    "register-waste": {
      "title": "Registra",
      "quantity-title": "Escreva a quantidade a perder",
      "quantity-title-weighable": "Escaneie ou escreva a quantidade a perder",
      "units": "Unidades (unidades)",
      "grams": "Quantidade (gramas)",
      "total-qty": "Foram registrado {0}",
      "reason-title": "Selecione o motivo",
      "error": "Ocorreu um erro ao gravar a redução",
      "stock-error": "A quantidade a perder é maior que o estoque disponível do produto.",
      "reasons": {
        "EXPIRATION": "Vencimento",
        "QUALITY": "Qualidade",
        "RETURN": "Devolução",
        "HARMFUL": "Fauna nociva",
        "RECOVERY": "Recuperaçã",
        "SINISTER": "Sinistro",
        "APPEARANCE": "Aparência",
        "WEIGHT": "Calibre e/ou peso",
        "DAMAGE":"Dano mecânico",
        "DEFORMITY": "Deformidade",
        "DEHYDRATION": "Desidratação",
        "FUNGUS": "Fungos",
        "MATURATION": "Maturação",
        "OXIDATION": "Oxidação",
        "DECAY": "Apodrecimento"
      }
    }
  },
  "inventory-transfer": {
    "home": {
      "title": "Transferências de inventário",
      "type-error": "Esperava-se uma matriz de ordens de saída na resposta da receipt-api",
      "skus": "Qtd. SKU: {{total}}"
    },
    "detail": {
      "type-error": "Esperava-se dados de uma ordem da receipt-api",
      "picked-quantity": "Quantidade inserida:",
      "location": "Localização escaneada:",
      "to-pick": "Para Pickear",
      "picked": "Pickeado",
      "upc-sku": "UPC: {{barcode}} | SKU: {{sku}}",
      "requested": "Quantidade solicitada:",
      "not-to-pick": "Não há nada para pickear",
      "not-picked": "Nada pickeado ainda",
      "scan-product": "Escanear produto",
      "already-picked": "O produto escaneado já foi pickeado",
      "not-existing-upc": "O produto escaneado não existe na ordem atual",
      "missing": "Faltante",
      "missing-alert": "Tem certeza de que deseja marcar o produto como faltante?"
    },
    "pick": {
      "quantity": "Quantidade retirada",
      "quantity-gr": "Quantidade retirada em gramas",
      "scan": "Escanear localização",
      "success": "Produto pickeado com sucesso."
    },
    "container": {
      "alert": "Você deve escanear um contêiner, pois há vários contêineres na localização."
    }
  },
  "universal-search": {
    "scan": "Você pode escanear",
    "type-label": "Digite pallet, ubicação o UPC.",
    "type-placeholder": "Pallet, Ubicação o UPC.",
    "scan-anything": "Produto, Contêiner, Ubicação",
    "toolbar-title": "Buscador",
    "quantity": "Quantidade:",
    "storage": "armazenagem",
    "prestorage": "pré-armazenagem",
    "picking": "picking",
    "restocking-rules": "Regras de reabastecimento",
    "product":{
      "toolbar-title": "Ubicações do produto",
      "scanned-product": "Produto escaneado",
      "no-inventory": "Não há inventário na ubicação do {{type}}",
      "no-rules": "Não há regras de reabastecimento para este produto"
    },
    "container":{
      "toolbar-title": "Detalhe do pallet",
      "scanned-container": "Pallet escaneado",
      "products-in-container": "Produtos do pallet"
    },
    "location":{
      "toolbar-title": "Detalle de ubicación de {{type}}",
      "scanned-location": "Ubicação escaneada",
      "products-in-location": "Produtos na ubicação",
      "containers-in-location": "Pallets na ubicação"
    }
  },
  "reverse-logistics":{
    "toolbar-title": "Logística inversa",
    "scan-tare-or-product": "Escaneie a caixa ou o produto para iniciar o despickeo:",
    "info-message": "Comece com os produtos que se mermam mais facilmente.",
    "select-report-type": "Selecione o tipo de relatório para {{product}}",
    "tare-id": "ID da caixa:",
    "order": "Pedido:",
    "scanned-product": "Produto escaneado",
    "tare-not-found": "Caixa não encontrada na logística inversa.",
    "tare-not-valid": "A caixa escaneada não é válida",
    "product-not-found": "Produto não encontrado na logística inversa.",
    "scan-product": {
      "toolbar-title": "Despickear",
      "scan": "Escaneie o produto a despickear:",
      "scanned-tare": "Caixa escaneada",
      "error-not-found": "O produto escaneado não está na caixa escaneada."
    },
    "register":{
      "toolbar-title": "Registra",
      "stock-error": "A quantidade a registrar é maior que o estoque disponível do produto.",
      "instructions": "Instruções",
      "set-quantity": "Escreva a quantidade a despickear.",
      "set-quantity-weighable": "Escreva ou escaneie a quantidade a despickear.",
      "scan-destination": "Depois, escaneie a ubicação de destino.",
      "suggested-location": "Ubicação sugerida",
      "no-quantity": "Você deve escrever a quantidade a despickear para poder escanear uma ubicação de destino.",
      "success": "Foram despickeados os {{quantity}} produtos da caixa com ID {{location}}.",
      "invalid-location": "A ubicação escaneada está incorreta, você deve escanear uma das ubicações sugeridas.",
      "different-product": "O produto digitalizado não é compatível com o produto a ser reabastecido."
    },
    "register-waste-or-missing":{
      "toolbar-title": "Relatório de {{flow}}",
      "write-quantity": "Escreva a quantidade {{flow}}:",
      "quantity": "Quantidade:",
      "success-missing": "Foram registradas como faltante {{quantity}} {{unit}} de {{product}}.",
      "success-waste": "Foram registradas como merma {{quantity}} {{unit}} de {{product}}.",
      "stock-error": "A quantidade a registrar é maior que o estoque disponível do produto.",
    }
  }
}
