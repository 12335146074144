import { useScanner } from '../../../../hooks/scanner.hook'
import { useEffect, useState } from 'react'
import { getProductFromScan } from 'client/scanner/scanner.client'
import { manageError } from 'utils/errors/error.handler'
import { type AlertSuggestionProduct, type ProductInfo } from 'types/model'
import { useConfirmationDialog } from 'components/molecules/ConfirmationDialog.molecule'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { tabs } from './ScanProduct.helpers'
import { useAlertsRegisterRestockNavigator } from '../register-restock/RegisterRestock.navigator'
import { getActiveProductsFromSuggestion } from 'client/alerts/alerts.client'
import { useNavigate, useParams } from 'react-router-dom'
import { getProducts } from '../../../../client/inventory/inventory.client'
import { findProductsInfo } from '../../../../client/products/products.client'
import { useAtom } from 'jotai/index'
import { AlertsSelectedAtom, ProductSelectedAtom } from '../Alerts.state'

export interface ProductWithStock extends ProductInfo {
  stock: number
}

export const useScanProduct = () => {
  const { t } = useTranslation('global')

  const [, setAlertSelected] = useAtom(AlertsSelectedAtom)
  const [, setProductSelected] = useAtom(ProductSelectedAtom)

  const [loading, setLoading] = useState(false)
  const [alerts, setAlerts] = useState<AlertSuggestionProduct[]>([])
  const [products, setProducts] = useState<ProductWithStock[]>([])
  const [activeTab, setActiveTab] = useState('Urgent')

  const {
    keys,
    getScan
  } = useScanner()
  const {
    dialogRef: typeProductDialogRef,
    open: openTypeProductDialog
  } = useConfirmationDialog()
  const { source } = useParams()
  const navigate = useNavigate()

  const registerNavigator = useAlertsRegisterRestockNavigator()

  const onChangeTab = (selectedTab: string) => {
    setActiveTab(selectedTab)
  }

  const getTabs = () => {
    return tabs.map((id) => ({
      id,
      label: t(`alerts.scan-product.tabs.${id}`, {
        counter: alerts.filter(p => p.level === id).length || 0
      })
    }))
  }

  const searchProduct = (ean: string) => {
    setLoading(true)
    getProductFromScan(ean)
      .then(e => {
        const productSelected = products.find(p => p.ean === e.product.ean)
        const alertSelected = alerts.find((a) => a.productEan === e.product.ean)
        if (productSelected) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          registerNavigator.go(source!, ean)
          setAlertSelected(alertSelected)
          setProductSelected(productSelected)
        } else {
          toast.error(t('alerts.scan-product.error-not-in-list', { product: e.product.name }))
        }
      })
      .catch(e => {
        console.error(e)
        toast.error('Error al obtener producto: ' + ean)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /* On init */
  useEffect(() => {
    if (source) {
      setLoading(true)
      Promise.all([
        getActiveProductsFromSuggestion(source),
        getProducts(source)
      ]).then(async ([alerts, products]) => {
        setAlerts(alerts)
        const res = await findProductsInfo(products.map((p) => p.productEan))
        setProducts(res.map((it): ProductWithStock => {
          return { ...it, stock: products.find((p) => p.productEan === it.ean)?.stock ?? 0 }
        }))
      }).catch((err: Error) => {
        manageError(err.message)
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [])

  /* On scan */
  useEffect(() => {
    const scanned = getScan()
    if (scanned) {
      searchProduct(scanned)
      console.log({ scanned })
    }
  }, [keys])

  return {
    source,
    hasAlerts: alerts.length > 0,
    totalAlerts: alerts.length,
    alerts: alerts.filter((a) => a.level === activeTab),
    products,
    isLoading: loading,
    typeProductDialogRef,
    actions: {
      openTypeProductDialog,
      searchProduct,
      onChangeTab,
      getTabs,
      close: () => {
        navigate(-1)
      }
    }
  }
}
